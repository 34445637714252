import { AttendanceComponent } from "./../pages/attendance/attendance.component";
import { ILeaveReport } from "./../models/leave-report";
import { AppComfirmComponent } from "./../shared/services/app-confirm/app-confirm.component";
import { WorkingDays } from "./../models/attendance-report";
import { updateDoc, getDoc, DocumentData } from "@angular/fire/firestore";
import { FirebaseDbService } from "./firebase-db.service";
import { Injectable } from "@angular/core";
import { DB_PATH } from "app/constants/db-path";
import { FirestoreService } from "./firestore.service";
import {
  AttendanceStatus,
  MessageQueueStatus,
  WhereFilterOp,
} from "app/enums/enums";
import { Cluster } from "app/models/cluster";
import { lastValueFrom, map, Observable } from "rxjs";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { QueryFilter } from "app/models/query-filters";
import {
  chunkArray,
  salespersonAttendanceGuid,
  timeStamp,
} from "app/utility/utility";
import { MessageQueue } from "app/models/task-preview";
import { IAttendanceReport } from "app/models/attendance-report";
import {
  DailyCustomersReport,
  DailyData,
  DailyPerformanceIncentiveReport,
  MonthlyCustomerReport,
  MonthlyData,
  MonthlyIncentiveReport,
  MtdSalesPerformance,
  SalesPersonReportResponse,
  SalespersonSearchInput,
} from "app/models/salesperson-report";

@Injectable({
  providedIn: "root",
})
export class SalesPersonService {
  constructor(
    public firestoreService: FirestoreService,
    private fireBaseService: FirebaseDbService,
    public http: HttpClient
  ) {}

  private mapToCluster(data: Cluster[] | Cluster) {
    if (Array.isArray(data)) {
      return data
        .map((c) => {
          return {
            id: c.id,
            name: c.name,
            type: c.type,
            searchKey: c.searchKey,
            customerCount: c.customerCount || 0,
            coordinates: c.coordinates,
            createdDate: c.createdDate,
            updatedDate: c.updatedDate,
            customers: c.customers,
          };
        })
        .sort((a, b) => b.customerCount - a.customerCount);
    } else {
      return {
        id: data.id,
        name: data.name,
        type: data.type,
        searchKey: data.searchKey,
        customerCount: data.customerCount,
        coordinates: data.coordinates,
        createdDate: data.createdDate,
        updatedDate: data.updatedDate,
        customers: data.customers,
      };
    }
  }
  async getAllClusters(): Promise<any> {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.SALES_PERSON_CLUSTERS
    );

    return this.mapToCluster(response as Cluster[]);
  }

  async getCluster(clusterId: string): Promise<Cluster> {
    const data = await this.firestoreService.fetchDocument(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId
    );

    if (data) {
      // Cast the data to Cluster
      // return data as Cluster;

      return this.mapToCluster(data as Cluster) as Cluster;
    } else {
      // Handle the case where the document is not found
      throw new Error("No such document!");
    }
  }

  async createCluster(cluster: Cluster) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.SALES_PERSON_CLUSTERS,
      cluster.id,
      cluster
    );
  }

  async updateCluster(clusterId: string, updateObj: any) {
    return await this.firestoreService.updateDocument(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId,
      updateObj
    );
  }

  async getClusterCustomers(clusterId: string) {
    return await this.firestoreService.fetchSubCollection(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId,
      DB_PATH.CUSTOMERS
    );
  }

  async getClusterCustomersIds(clusterId: string) {
    return await this.firestoreService.fetchSubCollectionDocIds(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId,
      DB_PATH.CUSTOMERS
    );
  }

  async deleteCluster(clusterId: string) {
    return await this.firestoreService.deleteDoc(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId
    );
  }

  async deleteClusterCustomers(clusterId: string) {
    return await this.firestoreService.deleteFieldDoc(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId,
      "customers"
    );
  }

  async addCustomersToClusterInBatches(clusterId: string, customers: any[]) {
    return this.firestoreService.writeDataToSubCollectionInBatches(
      DB_PATH.SALES_PERSON_CLUSTERS,
      clusterId,
      DB_PATH.CUSTOMERS,
      customers
    );
  }

  private mapToSalesPerson(salesPersons: Array<any> = []) {
    // TODO need to define type of sales person

    return salesPersons
      .map((salesPerson) => {
        return {
          name: salesPerson.name || "",
          mobileNumber: salesPerson.mobileNumber || "",
          id: salesPerson.id || "",
          businessName: salesPerson.businessName || "",
          isActive: salesPerson.isActive || false,
          areaName: salesPerson.cluster ? salesPerson.cluster.name || "" : "",
          areaId: salesPerson.cluster ? salesPerson.cluster.id || "" : "",
          area: salesPerson.cluster || {},
          customerCount: salesPerson.customerCount || 0,
          salesLeadId: salesPerson.salesLeadId || "",
          salesLeadName: salesPerson.salesLead
            ? salesPerson.salesLead.name || ""
            : "",
          salesLeadMobileNumber: salesPerson.salesLead
            ? salesPerson.salesLead.mobileNumber || ""
            : "",
          salesManagerId: salesPerson.salesManagerId || "",
          salesManagerName: salesPerson.salesManager
            ? salesPerson.salesManager.name || ""
            : "",
          salesManagerMobileNumber: salesPerson.salesManager
            ? salesPerson.salesManager.mobileNumber || ""
            : "",
          enableLogOut: salesPerson.enableLogOut
            ? salesPerson.enableLogOut || false
            : false,
        };
      })
      .sort((a, b) => b.customerCount - a.customerCount);
  }
  async getSalesPersons() {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.SALES_PERSON
    );
    return this.mapToSalesPerson(response);
  }

  async createSalesPersons(id: string, input: any) {
    const response = await this.firestoreService.insertDocWithId(
      DB_PATH.SALES_PERSON,
      id,
      input
    );
  }

  async getSalesPersonById(id: string) {
    const response = await this.firestoreService.fetchDocument(
      DB_PATH.SALES_PERSON,
      id
    );
    return response;
  }

  async getSalesPersonByClusterId(clusterId: string): Promise<any> {
    const filters: Array<QueryFilter> = [
      {
        fieldName: "clusterId",
        fieldValue: clusterId,
        operator: WhereFilterOp.EqualTo,
      },
    ];
    const response = await this.firestoreService.queryCollectionByFilters(
      DB_PATH.SALES_PERSON,
      filters
    );

    return response && response[0];
  }

  async getSalesPersonsByClusterIds(clusterIds: string[]): Promise<any[]> {
    const chunkedClusterIds = chunkArray(clusterIds, 10);
    const responseChunks = await Promise.all(
      chunkedClusterIds.map(async (chunk) => {
        const filters: QueryFilter[] = [
          {
            fieldName: "clusterId",
            fieldValue: chunk,
            operator: WhereFilterOp.In,
          },
        ];
        const chunkResponse =
          await this.firestoreService.queryCollectionByFilters(
            DB_PATH.SALES_PERSON,
            filters
          );
        return chunkResponse || [];
      })
    );

    return responseChunks.flat();
  }

  async getMappedSalesPersonFromClusterIds(clusterIds: string[]) {
    const salesPersons: any[] = await this.getSalesPersonsByClusterIds(
      clusterIds
    );

    return salesPersons.map((s) => {
      return {
        salesPersonName: s.businessName,
        salesPersonId: s.id,
        clusterName: s.cluster.name,
        clusterId: s.cluster.id,
      };
    });
  }
  async updateSalesPersonStatus(salesPersonId: string, updateObject: Object) {
    const response = await this.fireBaseService.updateData(
      `${DB_PATH.CUSTOMER}/${salesPersonId}`,
      updateObject
    );

    // Updating in Firestore Sales Person Node.

    return await this.firestoreService.updateDocument(
      DB_PATH.SALES_PERSON,
      salesPersonId,
      updateObject
    );
  }

  async updateSalesPersonCluster(salesPersonId: string, updateObject: Object) {
    // updating in FireStore SalesPerson Node

    return await this.firestoreService.updateDocument(
      DB_PATH.SALES_PERSON,
      salesPersonId,
      updateObject
    );
  }

  async getTask(taskId: string) {
    return await this.firestoreService.fetchDocument(
      DB_PATH.TASKS_REPORT,
      taskId
    );
  }

  async getTasksReportByFilter(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = []
  ): Promise<any> {
    try {
      const startDate1: number = timeStamp(`${startDate} 00:00:00`);
      const endDate1: number = timeStamp(`${endDate} 23:59:59`);

      const filters: Array<any> = [
        {
          fieldName: "taskDateTimestamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: startDate1,
        },
        {
          fieldName: "taskDateTimestamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: endDate1,
        },
      ];

      if (salesPersonIds.length > 0) {
        // Todo Need check for the condition for where sales person Ids should be less then 10
        filters.push({
          fieldName: "salesPersonId",
          operator: WhereFilterOp.In,
          fieldValue: salesPersonIds,
        });
      }

      const response = await this.firestoreService.queryCollectionByFilters(
        DB_PATH.TASKS_REPORT,
        filters
      );

      return response;
    } catch (error) {
      console.log("getTasksReportByFilter", error);
      throw error;
    }
  }

  async getTasksByFilter(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = []
  ): Promise<any> {
    try {
      const startDate1: number = timeStamp(`${startDate} 00:00:00`);
      const endDate1: number = timeStamp(`${endDate} 23:59:59`);

      const filters: Array<any> = [
        {
          fieldName: "taskDateTimestamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: startDate1,
        },
        {
          fieldName: "taskDateTimestamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: endDate1,
        },
      ];

      if (salesPersonIds.length > 0) {
        // Todo Need check for the condition for where sales person Ids should be less then 10
        filters.push({
          fieldName: "salesPersonId",
          operator: WhereFilterOp.In,
          fieldValue: salesPersonIds,
        });
      }

      const response = await this.firestoreService.queryCollectionByFilters(
        DB_PATH.TASKS,
        filters
      );

      return response;
    } catch (error) {
      console.log("getTasksReportByFilter", error);
      throw error;
    }
  }

  async getInteractionsByFilters(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = [],
    mobileNumber: string,
    lastVisibleDocTimeStamp: number,
    nextPage: boolean,
    firstVisibleDocTimeStamp: number,
    prevPage: boolean
  ): Promise<any> {
    const startDate1: number = timeStamp(`${startDate} 00:00:00`);
    const endDate1: number = timeStamp(`${endDate} 23:59:59`);

    let filters: Array<any> = [];

    if (nextPage) {
      filters = [
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: startDate1,
        },
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: lastVisibleDocTimeStamp,
        },
      ];
    } else if (prevPage) {
      filters = [
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: firstVisibleDocTimeStamp,
        },
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: endDate1,
        },
      ];
    } else {
      filters = [
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: startDate1,
        },
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: endDate1,
        },
      ];
    }

    if (mobileNumber.length > 0) {
      filters.push({
        fieldName: "customerInfo.mobileNumber",
        operator: WhereFilterOp.EqualTo,
        fieldValue: mobileNumber,
      });
    }

    if (salesPersonIds.length > 0) {
      filters.push({
        fieldName: "salesPersonId",
        operator: WhereFilterOp.In,
        fieldValue: salesPersonIds,
      });
    }

    return (
      (await this.firestoreService.queryNextPage(
        DB_PATH.INTERACTIONS,
        filters,
        "createdTimeStamp",
        50,
        prevPage
      )) || []
    );
  }

  async downloadInteractionsByFilters(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = [],
    mobileNumber: string
  ): Promise<any> {
    const startDate1: number = timeStamp(`${startDate} 00:00:00`);
    const endDate1: number = timeStamp(`${endDate} 23:59:59`);

    const filters: Array<any> = [
      {
        fieldName: "createdTimeStamp",
        operator: WhereFilterOp.GreaterThanOrEqualTo,
        fieldValue: startDate1,
      },
      {
        fieldName: "createdTimeStamp",
        operator: WhereFilterOp.LessThanOrEqualTo,
        fieldValue: endDate1,
      },
    ];
    if (mobileNumber.length > 0) {
      filters.push({
        fieldName: "customerInfo.mobileNumber",
        operator: WhereFilterOp.EqualTo,
        fieldValue: mobileNumber,
      });
    }
    if (salesPersonIds.length > 0) {
      filters.push({
        fieldName: "salesPersonId",
        operator: WhereFilterOp.In,
        fieldValue: salesPersonIds,
      });
    }

    return (
      (await this.firestoreService.queryCollectionByFilters(
        DB_PATH.INTERACTIONS,
        filters
      )) || []
    );
  }

  async getTasksByTaskDateAndSalesPerson(
    date: string,
    salesPersonId: string
  ): Promise<any> {
    const filters: Array<any> = [
      {
        fieldName: "taskDate",
        operator: WhereFilterOp.EqualTo,
        fieldValue: date,
      },
      {
        fieldName: "salesPersonId",
        operator: WhereFilterOp.EqualTo,
        fieldValue: salesPersonId,
      },
    ];
    const response = await this.firestoreService.queryCollectionByFilters(
      DB_PATH.TASKS,
      filters
    );

    return response;
  }

  async getDealers(): Promise<any[]> {
    const dbPath = `${DB_PATH.APP_SETTINGS}/common/dealerNames`;
    const observable = this.fireBaseService.fetchDataFromNode(dbPath).pipe(
      map((data) => {
        return Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
      })
    );

    return lastValueFrom(observable);
  }

  getDealersObservable(): Observable<any[]> {
    const dbPath = `${DB_PATH.APP_SETTINGS}/common/dealerNames`;
    return this.fireBaseService.fetchDataFromNode(dbPath).pipe(
      map((data) => {
        return Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
      })
    );
  }

  async getCustomerCount(payLoad: any = {}) {
    try {
      const url: string = `${environment.coreUrl}/customer/count_by_salesperson`;
      const observable = this.http.post(url, payLoad);

      const response: any = await lastValueFrom(observable);

      return response?.data;
    } catch (error) {
      console.log("getCustomerCount error", error);
    }
  }
  async updateSalePersonDb(id: string, input: any) {
    try {
      const url: string = `${environment.coreUrl}/customer/${id}`;
      const observable = this.http.put(url, input);
      const response: any = await lastValueFrom(observable);
      return response;
    } catch (error) {
      console.log("getCustomerCount error", error);
    }
  }
  async unMapSalesPerson(salesPersonId: string): Promise<any> {
    try {
      const inputObj = {
        salesPersonIds: [salesPersonId],
        salesPersonId: "",
        customerIds: [],
      };

      const url: string = `${environment.coreUrl}/customer/update_salesperson_ids`;
      const observable = this.http.post<any>(url, inputObj);

      const result = await lastValueFrom(observable);

      return result?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async mapSalesPerson(
    salesPersonId: string,
    customerIds: string[]
  ): Promise<any> {
    try {
      const inputObj = {
        salesPersonIds: [],
        salesPersonId: salesPersonId,
        customerIds: customerIds,
      };
      const url: string = `${environment.coreUrl}/customer/update_salesperson_ids`;

      const observable = this.http.post<any>(url, inputObj);
      const response = await lastValueFrom(observable);

      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async getMessageQueueStatus() {
    const filters: Array<any> = [
      {
        fieldName: "status",
        operator: WhereFilterOp.EqualTo,
        fieldValue: MessageQueueStatus.Started,
      },
    ];

    const response = await this.firestoreService.queryCollectionByFilters(
      DB_PATH.MESSAGE_QUEUE_STATUS,
      filters
    );

    return response && response[0];
  }

  async updateStatusInMessageQueueFS(updateObj: MessageQueue) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.MESSAGE_QUEUE_STATUS,
      updateObj.messageQueueStatusId,
      updateObj
    );
  }

  async getInteraction(interactionId: string) {
    return await this.firestoreService.fetchDocument(
      DB_PATH.INTERACTIONS,
      interactionId
    );
  }

  async updateSalesPerson(salesPersonId: string, updateObject: Object) {
    // await this.fireBaseService.updateData(`${DB_PATH.CUSTOMER}/${salesPersonId}`, updateObject);

    return await this.firestoreService.updateDocument(
      DB_PATH.SALES_PERSON,
      salesPersonId,
      updateObject
    );
  }
  async updateSalesPersonProfile(salesPersonId: string, updateObject: Object) {
    return await this.firestoreService.updateDocument(
      DB_PATH.SALES_PERSON,
      salesPersonId,
      updateObject
    );
  }

  async getDetails(
    collectionPath: string,
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = [],
    salesLeadIds: Array<string> = []
  ): Promise<any> {
    const startDate1: number = timeStamp(`${startDate} 00:00:00`);
    const endDate1: number = timeStamp(`${endDate} 23:59:59`);
    const filters: Array<any> = [
      {
        fieldName: "createdTimeStamp",
        operator: WhereFilterOp.GreaterThanOrEqualTo,
        fieldValue: startDate1,
      },
      {
        fieldName: "createdTimeStamp",
        operator: WhereFilterOp.LessThanOrEqualTo,
        fieldValue: endDate1,
      },
    ];

    if (salesPersonIds.length > 0) {
      filters.push({
        fieldName: "salesPersonId",
        operator: WhereFilterOp.In,
        fieldValue: salesPersonIds,
      });
    }

    if (salesLeadIds.length > 0) {
      filters.push({
        fieldName: "salesLeadId",
        operator: WhereFilterOp.In,
        fieldValue: salesLeadIds,
      });
    }

    const response = await this.firestoreService.queryCollectionByFilters(
      collectionPath,
      filters
    );

    return response;
  }

  async getAttendanceDetails(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = [],
    salesLeadIds: Array<string> = []
  ): Promise<any> {
    return await this.getDetails(
      DB_PATH.ATTENDANCE_REPORT,
      startDate,
      endDate,
      salesPersonIds,
      salesLeadIds
    );
  }

  async getLeaveDetails(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = [],
    salesLeadIds: Array<string> = []
  ): Promise<any> {
    return await this.getDetails(
      DB_PATH.LEAVE_REPORT,
      startDate,
      endDate,
      salesPersonIds,
      salesLeadIds
    );
  }

  async updateAttendanceStatus(id: string, updateObj: any) {
    if (
      updateObj.status === AttendanceStatus.REASON_FOR_EARLY_LOGOUT_ACCEPTED
    ) {
      updateObj.checkOutTime = Date.now();

      return await this.firestoreService.updateDocument(
        DB_PATH.ATTENDANCE_REPORT,
        id,
        updateObj
      );
    }

    return await this.firestoreService.updateDocument(
      DB_PATH.ATTENDANCE_REPORT,
      id,
      updateObj
    );
  }

  async updateLeaveStatus(id: string, updateObj: any) {
    if (updateObj.status === AttendanceStatus.LEAVE_ACCEPTED) {
      const leaveReport = await this.createLeaveReportFromId(id);
      await this.firestoreService.insertDocWithId(
        DB_PATH.ATTENDANCE_REPORT,
        leaveReport.id,
        leaveReport
      );
    }

    return await this.firestoreService.updateDocument(
      DB_PATH.LEAVE_REPORT,
      id,
      updateObj
    );
  }

  private async createLeaveReportFromId(id: string) {
    const getDoc = await this.getLeaveReport(id);
    const leaveReport = {
      id: salespersonAttendanceGuid(),
      leaveId: id,
      createdDate: getDoc.appliedOn,
      status: AttendanceStatus.LEAVE_ACCEPTED,
      salesPersonId: getDoc.salesPersonId,
      createdTimeStamp: getDoc.createdTimeStamp,
    };

    return leaveReport;
  }

  async getLeaveReport(id: string): Promise<any> {
    return await this.firestoreService.fetchDocument(DB_PATH.LEAVE_REPORT, id);
  }

  async getAttendanceReport(
    startDate: string,
    endDate: string,
    salesPersonIds: Array<string> = []
  ): Promise<any> {
    return await this.getDetails(
      DB_PATH.ATTENDANCE_REPORT,
      startDate,
      endDate,
      salesPersonIds,
      []
    );
  }

  async getFilteredCollection(
    salesPersonId: string,
    startDate: string,
    endDate: string,
    collectionPath: string
  ) {
    try {
      const startDate1: number = timeStamp(`${startDate} 00:00:00`);
      const endDate1: number = timeStamp(`${endDate} 23:59:59`);

      const filters: Array<any> = [
        {
          fieldName: "salesPersonId",
          operator: WhereFilterOp.EqualTo,
          fieldValue: salesPersonId,
        },
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.GreaterThanOrEqualTo,
          fieldValue: startDate1,
        },
        {
          fieldName: "createdTimeStamp",
          operator: WhereFilterOp.LessThanOrEqualTo,
          fieldValue: endDate1,
        },
      ];
      const response = await this.firestoreService.queryCollectionByFilters(
        collectionPath,
        filters
      );

      return response || [];
    } catch (error) {
      console.error(
        `Error in getFilteredCollection for ${collectionPath}:`,
        error
      );
      return [];
    }
  }

  async getAttendanceReportBySalesPersonId(
    salesPersonId: string,
    startDate: string,
    endDate: string
  ) {
    return await this.getFilteredCollection(
      salesPersonId,
      startDate,
      endDate,
      DB_PATH.ATTENDANCE_REPORT
    );
  }

  async getCalendar(docId: string): Promise<any> {
    const response = await this.firestoreService.fetchDocument(
      DB_PATH.CALENDAR,
      docId
    );

    if (response) {
      return this.mapToWorkingDays(response as WorkingDays) as WorkingDays;
    } else {
      return [];
    }
  }

  private mapToWorkingDays(data: WorkingDays[] | WorkingDays) {
    if (Array.isArray(data)) {
      return data.map((c) => {
        return {
          month: c.month,
          workingDays: c.workingDays,
          monthName: c.monthName,
          days: c.days,
          year: c.year,
        };
      });
    } else {
      return {
        month: data.month,
        workingDays: data.workingDays,
        monthName: data.monthName,
        days: data.days,
        year: data.year,
      };
    }
  }

  async getSalesPersonsReport(
    salespersonReportInput: SalespersonSearchInput
  ): Promise<any> {
    try {
      const url: string = `${environment.cloudFunctionsUrl}/getSalesPersonReport`;
      const observable: Observable<any> = this.http.post(
        url,
        salespersonReportInput
      );

      const response: any = await lastValueFrom(observable);
      if (response && response["response"]) {
        const salesPersonList = await this.getSalesPersons();
        const updatedSalespersonPerformance = this.mapSalesLeadName(
          salesPersonList,
          response["response"]
        );

        return this.mapData(updatedSalespersonPerformance);
      } else {
        return {};
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  private mapData(
    salesPersonReportData: SalesPersonReportResponse["response"]
  ): any {
    return {
      mtdRevenueReport: this.mapMTDRevenueReport(salesPersonReportData.monthly),
      dailyPerformanceIncentiveReport: this.mapDailyPerformanceIncentiveReport(
        salesPersonReportData.daily
      ),
      dailyCustomersReport: this.mapDailyCustomerReport(
        salesPersonReportData.daily
      ),
      monthlyCustomerReport: this.mapMonthlyCustomerReport(
        salesPersonReportData.monthly
      ),
      monthlyIncentiveReport: this.mapMonthlyIncentiveReport(
        salesPersonReportData.monthly
      ),
    };
  }

  private mapMTDRevenueReport(monthData: MonthlyData[]): MtdSalesPerformance[] {
    return monthData.map((data) => ({
      "SalesPerson Id": data.salesPersonId || "",
      "TL Name": data.teamLeadName || "",
      "BA Name": data.salesPersonName,
      Area: data.areaName,
      "Buying Target": data.monthProductivityTarget,
      "Buying Achieved": data.monthProductivityCount,
      "Buying Achieved %": data.productivityPercent,
      "Revenue Target": data.monthOverallRevenueTarget,
      "Revenue Achieved": data.monthOverallRevenue,
      "Revenue Achieved %": data.overallRevenuePercent,
      "FMCG Revenue Target": data.monthFMCGRevenueTarget,
      "FMCG Revenue Achieved": data.monthFMCGRevenue,
      "FMCG Revenue Achieved %": data.fmcgRevenuePercent,
      "Today Target": data.todayTarget,
    }));
  }

  private mapMonthlyIncentiveReport(
    monthData: MonthlyData[]
  ): MonthlyIncentiveReport[] {
    return monthData.map((data) => ({
      "SalesPerson Id": data.salesPersonId || "",
      "TL Name": data.teamLeadName || "",
      "BA Name": data.salesPersonName,
      "Monthly Buying Customer Count Incentive":
        data.monthProductivityIncentive,
      "Monthly Revenue Incentive": data.monthOverallRevenueIncentive,
      "Monthly FMCG Revenue Incentive": data.monthFMCGRevenueIncentive,
      "Daily FMCG Line Items Incentive": data.monthDailyFMCGLineItemsIncentive,
      "Daily Productivity Incentive": data.monthDailyProductivityIncentive,
    }));
  }

  private mapMonthlyCustomerReport(
    monthData: MonthlyData[]
  ): MonthlyCustomerReport[] {
    return monthData.map((data) => ({
      "SalesPerson Id": data.salesPersonId || "",
      "TL Name": data.teamLeadName || "",
      "BA Name": data.salesPersonName,
      "Sales Person Assigned": data.monthSalesPersonAssigned,
      "Self Order Assigned": data.monthSelfOrderAssigned,
      "Sales Person UnAssigned": data.monthSalesPersonUnAssigned,
      "Self Order UnAssigned": data.monthSelfOrderUnAssigned,
      "Sales Person Assigned Revenue": data.monthSalesPersonAssignedRevenue,
      "Self Order Assigned Revenue": data.monthSelfOrderAssignedRevenue,
      "Sales Person UnAssigned Revenue": data.monthSalesPersonUnAssignedRevenue,
      "Self Order UnAssigned Revenue": data.monthSelfOrderUnAssignedRevenue,
    }));
  }

  private mapDailyPerformanceIncentiveReport(
    dailyPerformanceIncentiveReport: DailyData[]
  ): DailyPerformanceIncentiveReport[] {
    return dailyPerformanceIncentiveReport.map((incentive) => ({
      "SalesPerson Id": incentive.salesPersonId || "",
      "TL Name": incentive.teamLeadName || "",
      "BA Name": incentive.salesPersonName,
      Tasks: incentive.tasksCount,
      Achieved: incentive.achievedTasksCount,
      "Achieved %": incentive.tasksPercent,
      "Productivity Count": incentive.productivityCount,
      "Productivity Revenue": incentive.productivityRevenue,
      "AVG Bill Value": incentive.avgOrderValue,
      "FMCG Orders": incentive.fmcgOrders,
      "Productivity Incentive": incentive.productivityIncentive,
      "FMCG LineItems Incentive": incentive.fmcgLineItemsIncentive,
    }));
  }

  private mapDailyCustomerReport(
    dailyPerformanceIncentiveReport: DailyData[]
  ): DailyCustomersReport[] {
    return dailyPerformanceIncentiveReport.map((customerData) => ({
      "SalesPerson Id": customerData.salesPersonId || "",
      "TL Name": customerData.teamLeadName || "",
      "BA Name": customerData.salesPersonName,
      "Sales Person Assigned": customerData.salesPersonAssigned,
      "Self Order Assigned": customerData.selfOrderAssigned,
      "Sales Person UnAssigned": customerData.salesPersonUnAssigned,
      "Self Order UnAssigned": customerData.selfOrderUnAssigned,
      "Sales Person Assigned Revenue": customerData.salesPersonAssignedRevenue,
      "Self Order Assigned Revenue": customerData.selfOrderAssignedRevenue,
      "Sales Person UnAssigned Revenue":
        customerData.salesPersonUnAssignedRevenue,
      "Self Order UnAssigned Revenue": customerData.selfOrderUnAssignedRevenue,
    }));
  }

  private mapSalesLeadName(
    salespersonList: any[],
    salespersonPerformance: any
  ) {
    const mapLeadNameToEntries = (entries: any[]) => {
      return entries.map((entry) => {
        const matchedSalesperson = salespersonList.find(
          (salesperson) => salesperson.id === entry.salesPersonId
        );
        const teamLeadName = matchedSalesperson?.salesLeadName ?? "";
        const areaName = matchedSalesperson?.area?.name ?? "";
        return { ...entry, teamLeadName, areaName };
      });
    };

    salespersonPerformance.daily = mapLeadNameToEntries(
      salespersonPerformance.daily
    );
    salespersonPerformance.monthly = mapLeadNameToEntries(
      salespersonPerformance.monthly
    );

    return salespersonPerformance;
  }
}
