export const config = {
  apiUrl: 'https://dev-qwipo.azurewebsites.net/services/api',
  // apiUrl: 'http://ui-lib-demo-api.herokuapp.com',
  testUrl: 'https://dev-qwipo.azurewebsites.net/services/api/',
  coreApiUrl: "https://api.dev.qwipo.com/api",
  coreUrl: "https://core.dev.bms.qwipo.com/api",
  messagingServiceUrl: "https://messaging-service.dev.bms.qwipo.com/api/messagequeue/post",
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  cloudFunctionsUrl: 'https://us-central1-devqwipo.cloudfunctions.net',
  ssoUrl: "https://sso.dev.bms.qwipo.com",
  firebaseConfig: {
    apiKey: "AIzaSyDvil7FitRz5FvCvqV_elC9e9877TtImMU",
    authDomain: "devqwipo.firebaseapp.com",
    databaseURL: "https://devqwipo.firebaseio.com",
    projectId: "devqwipo",
    storageBucket: "devqwipo.appspot.com",
    messagingSenderId: "95302609868",
    appId: "1:95302609868:web:57d524c8593ac77f2ecb69",
    measurementId: "G-2G1N0P1XC9"
  }
}

export const testConfig = {
  apiUrl: 'https://test-qwipo.azurewebsites.net/services/api',
  // apiUrl: 'http://ui-lib-demo-api.herokuapp.com',
  testUrl: 'https://test-qwipo.azurewebsites.net/services/api/',
  coreApiUrl: "https://api.test.qwipo.com/api",
  coreUrl: "https://core.test.bms.qwipo.com/api",
  messagingServiceUrl: "https://messaging-service.test.bms.qwipo.com/api/messagequeue/post",
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  cloudFunctionsUrl: 'https://us-central1-test-qwipo.cloudfunctions.net',
  ssoUrl: "https://sso.test.bms.qwipo.com",
  firebaseConfig: {
    apiKey: "AIzaSyCQxan-AN2bk2GTyav2PfKU2raT_QYlNiA",
    authDomain: "test-qwipo.firebaseapp.com",
    databaseURL: "https://test-qwipo.firebaseio.com",
    projectId: "test-qwipo",
    storageBucket: "test-qwipo.appspot.com",
    messagingSenderId: "1002423689795",
    appId: "1:1002423689795:web:368c953cccebfe247b2af6",
    measurementId: "G-V0VVNGXRZ9"
  }
}

export const stagingConfig = {

  apiUrl: 'https://staging-qwipo.azurewebsites.net/services/api',
  // apiUrl: 'http://ui-lib-demo-api.herokuapp.com',
  testUrl: 'https://staging-qwipo.azurewebsites.net/services/api/',
  coreApiUrl: "https://api.staging.qwipo.com/api",
  coreUrl: "https://core.staging.bms.qwipo.com/api",
  messagingServiceUrl: "https://messaging-service.staging.bms.qwipo.com/api/messagequeue/post",
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  cloudFunctionsUrl: 'https://us-central1-staging-qwipo-2015.cloudfunctions.net',
  ssoUrl: "https://sso.staging.bms.qwipo.com",
  firebaseConfig: {
    apiKey: "AIzaSyDDMgqsy8EwbRHE7mRzO6jvZX0hwNXGJcs",
    authDomain: "staging-qwipo-2015.firebaseapp.com",
    databaseURL: "https://staging-qwipo-2015-default-rtdb.firebaseio.com",
    projectId: "staging-qwipo-2015",
    storageBucket: "staging-qwipo-2015.appspot.com",
    messagingSenderId: "1016985034148",
    appId: "1:1016985034148:web:8a64c128f2aaa815c47531",
    measurementId: "G-RKTLZXSC6K"
  }
}

export const prodConfig = {

  apiUrl: 'https://staging-qwipo.azurewebsites.net/services/api',
  // apiUrl: 'http://ui-lib-demo-api.herokuapp.com',
  testUrl: 'https://staging-qwipo.azurewebsites.net/services/api/',
  coreApiUrl: "https://api.staging.qwipo.com/api",
  coreUrl: "https://core.bms.qwipo.com/api",
  messagingServiceUrl: "https://messaging-queue.bms.qwipo.com/api/messagequeue/post",
  authRoles: {
    sa: ['SA'], // Only Super Admin has access
    admin: ['SA', 'Admin'], // Only SA & Admin has access
    editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
    user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
    guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
  },
  cloudFunctionsUrl: 'https://us-central1-qwipo-82b7a.cloudfunctions.net',
  ssoUrl: "https://sso.bms.qwipo.com",
  firebaseConfig: {
    apiKey: "AIzaSyDbV-CkDA4kFmMdmjgl_MSWIfqUh9YNSpE",
    authDomain: "qwipo-82b7a.firebaseapp.com",
    databaseURL: "https://qwipo-82b7a.firebaseio.com",
    projectId: "qwipo-82b7a",
    storageBucket: "qwipo-82b7a.appspot.com",
    messagingSenderId: "719176192291",
    appId: "1:719176192291:web:0bd89bef14d848cc2a3c96",
    measurementId: "G-BGYZX6JL0R"
  }
}